import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'
import Layout from '../components/Layout'

export const IndexPageTemplate = ({
                                      image,
                                      title,
                                      heading,
                                      subheading,
                                      mainpitch,
                                      description,
                                      heading2,
                                      description2,
                                  }) => (
    <div>
        <div
            className="full-width-image margin-top-0"
            style={{
                backgroundImage: `url(${
                    !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`,
                backgroundPosition: `top left`,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    height: '150px',
                    lineHeight: '1',
                    justifyContent: 'space-around',
                    alignItems: 'left',
                    flexDirection: 'column',
                }}
            >
                <h1
                    className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                    style={{
                        backgroundColor: 'rgb(0, 0, 0, 0.44)',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </h1>
                <h3
                    className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                        backgroundColor: 'rgb(0, 0, 0,0.44)',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.4em',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                    }}
                >
                    {subheading}
                </h3>
            </div>
        </div>
        <section className="section section--gradient">
            <div className="container">
                <div className="section">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <div className="content">
                                    <div className="tile">
                                        <h2 className="title">{mainpitch.title}</h2>
                                    </div>
                                    <div className="tile">
                                        <p>{mainpitch.description}</p>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-12">
                                        <h3 className="has-text-weight-semibold">
                                            {heading}
                                        </h3>
                                        <p>{description}</p>
                                        <h3 className="has-text-weight-semibold">
                                            {heading2}
                                        </h3>
                                        <p>{description2}</p>
                                        <Link className="button is-link offerte-button" to="/contact">
                                            VRAAG EEN OFFERTE AAN
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    description: PropTypes.string,
    heading2: PropTypes.string,
    description2: PropTypes.string
    ,
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
                heading2={frontmatter.heading2}
                description2={frontmatter.description2}
                intro={frontmatter.intro}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        heading2
        description2
      }
    }
  }
`
